import React from "react";

import "./Footer.css";

import logoImg from "./img/elp-logo-black-min.png";
import twitterIcon from "./img/ic_twitter.svg";
import discordIcon from "./img/ic_discord.svg";
import telegramIcon from "./img/ic_telegram.svg";
import githubIcon from "./img/ic_github.svg";
import mediumIcon from "./img/ic_medium.svg";
import gitbookIcon from "./img/ic_gitbook.svg";
import ChainlinkIcon from './img/chainlink.svg'
import FantomIcon from './img/logo-fantom.png'
import linktreeIcon from "./img/linktree_white.svg";
import logoEquityExchangeImg from "./img/Equity_iconlogo.svg";
import { NavLink } from "react-router-dom";

export default function Footer() {
  return (
    <div className="Footer">
      <div className="Footer-wrapper">
        <div className="Footer-logo">
        <span className="mx-2" style={{fontSize:25}}><b><span className="titletextcolor"><img
                      src={logoEquityExchangeImg}
                      style={{ width: "100%", height: "auto", maxWidth:"150px" }}
                      className="big"
                      alt="EQUAL Logo"
                    /></span></b></span>
        </div>
        <div>
          <p><a href="https://equalizer.exchange/" target="_blank" className="ahreftextcolorfooter">Powered by Equalizer.Exchange</a></p>
        </div>
        <div className="Footer-social-link-block smallscreensize">
          <a className="App-social-link" href="https://twitter.com/Equalizer0x" target="_blank" rel="noopener noreferrer">
            <img src={twitterIcon} alt="Twitter" />
          </a>
          {/* <a className="App-social-link" href="https://medium.com/@craniumexchange" target="_blank" rel="noopener noreferrer">
            <img src={mediumIcon} alt="Medium" />
          </a>  */}
          {/* <a className="App-social-link" href="https://github.com/equal/" target="_blank" rel="noopener noreferrer">
            <img src={githubIcon} alt="Github" />
          </a> */}
          <a className="App-social-link" href="https://docs.equalizer.exchange/" target="_blank" rel="noopener noreferrer">
            <img src={gitbookIcon} alt="Gitbook" />
          </a>
          {/* <a className="App-social-link" href="https://t.me/equal/" target="_blank" rel="noopener noreferrer">
            <img src={telegramIcon} alt="Telegram" />
          </a>
          <a className="App-social-link" href="https://linktr.ee/equal" target="_blank" rel="noopener noreferrer">
            <img src={linktreeIcon} alt="Linktree" />
          </a> */}
          <a className="App-social-link" href="https://discord.gg/6ubHeztmYV" target="_blank" rel="noopener noreferrer">
            <img src={discordIcon} alt="Discord" />
          </a>
        </div>
      </div>
    </div>
  );
}
