import { ApolloClient, InMemoryCache } from "@apollo/client";

export const chainlinkClient = createClient("https://api.thegraph.com/subgraphs/name/chimpydev/equity-prices"); // DONE

export const fantomGraphClient = createClient("https://api.thegraph.com/subgraphs/name/chimpydev/equity-core"); // DONE

// All Positions
export const positionsGraphClient = createClient("https://api.thegraph.com/subgraphs/name/chimpydev/equity-leaderboard"); // DONE

export const fantomReferralsGraphClient = createClient("https://api.thegraph.com/subgraphs/name/chimpydev/equity-referral"); // DONE


function createClient(uri) {
  return new ApolloClient({
    uri,
    cache: new InMemoryCache(),
  });
}
