import React from "react";

import useSWR from "swr";

import {
  PLACEHOLDER_ACCOUNT,
  fetcher,
  formatKeyAmount,
  getBalanceAndSupplyData,
  getDepositBalanceData,
  getVestingData,
  getStakingData,
  getProcessedData,
} from "../../Helpers";

import Vault from "../../abis/Vault.json";
import Reader from "../../abis/Reader.json";
import RewardReader from "../../abis/RewardReader.json";
import Token from "../../abis/Token.json";
import EquityManager from "../../abis/EquityManager.json";

import { useWeb3React } from "@web3-react/core";

import { useEqualPrice, useTotalEqualSupply } from "../../Api";

import { getContract } from "../../Addresses";

export default function APRLabel({ chainId, label }) {
  let { active } = useWeb3React();

  const rewardReaderAddress = getContract(chainId, "RewardReader");
  const readerAddress = getContract(chainId, "Reader");

  const vaultAddress = getContract(chainId, "Vault");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const equalAddress = getContract(chainId, "EQUAL");
  const esEqualAddress = getContract(chainId, "ES_EQUAL");
  const bnEqualAddress = getContract(chainId, "BN_EQUAL");
  const equityAddress = getContract(chainId, "EQUITY");

  const stakedEqualTrackerAddress = getContract(chainId, "StakedEqualTracker");
  const bonusEqualTrackerAddress = getContract(chainId, "BonusEqualTracker");
  const feeEqualTrackerAddress = getContract(chainId, "FeeEqualTracker");

  const stakedEquityTrackerAddress = getContract(chainId, "StakedEquityTracker");
  const feeEquityTrackerAddress = getContract(chainId, "FeeEquityTracker");

  const equityManagerAddress = getContract(chainId, "EquityManager");

  const equalVesterAddress = getContract(chainId, "EqualVester");
  const equityVesterAddress = getContract(chainId, "EquityVester");
  const equityTokenAddress = getContract(chainId, "EquityToken");

  const vesterAddresses = [equalVesterAddress, equityVesterAddress];

  const walletTokens = [equalAddress, esEqualAddress, equityTokenAddress, equityAddress, stakedEqualTrackerAddress];
  const depositTokens = [
    equalAddress,
    esEqualAddress,
    stakedEqualTrackerAddress,
    bonusEqualTrackerAddress,
    bnEqualAddress,
    equityAddress,
  ];
  const rewardTrackersForDepositBalances = [
    stakedEqualTrackerAddress,
    stakedEqualTrackerAddress,
    bonusEqualTrackerAddress,
    feeEqualTrackerAddress,
    feeEqualTrackerAddress,
    feeEquityTrackerAddress,
  ];
  const rewardTrackersForStakingInfo = [
    stakedEqualTrackerAddress,
    bonusEqualTrackerAddress,
    feeEqualTrackerAddress,
    stakedEquityTrackerAddress,
    feeEquityTrackerAddress,
  ];

  const { data: walletBalances } = useSWR(
    [`StakeV2:walletBalances:${active}`, chainId, readerAddress, "getTokenBalancesWithSupplies", PLACEHOLDER_ACCOUNT],
    {
      fetcher: fetcher(undefined, Reader, [walletTokens]),
    }
  );

  const { data: depositBalances } = useSWR(
    [`StakeV2:depositBalances:${active}`, chainId, rewardReaderAddress, "getDepositBalances", PLACEHOLDER_ACCOUNT],
    {
      fetcher: fetcher(undefined, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
    }
  );

  const { data: stakingInfo } = useSWR(
    [`StakeV2:stakingInfo:${active}`, chainId, rewardReaderAddress, "getStakingInfo", PLACEHOLDER_ACCOUNT],
    {
      fetcher: fetcher(undefined, RewardReader, [rewardTrackersForStakingInfo]),
    }
  );

  const { data: stakedEqualSupply } = useSWR(
    [`StakeV2:stakedEqualSupply:${active}`, chainId, equalAddress, "balanceOf", stakedEqualTrackerAddress],
    {
      fetcher: fetcher(undefined, Token),
    }
  );

  const { data: aums } = useSWR([`StakeV2:getAums:${active}`, chainId, equityManagerAddress, "getAums"], {
    fetcher: fetcher(undefined, EquityManager),
  });

  const { data: nativeTokenPrice } = useSWR(
    [`StakeV2:nativeTokenPrice:${active}`, chainId, vaultAddress, "getMinPrice", nativeTokenAddress],
    {
      fetcher: fetcher(undefined, Vault),
    }
  );

  const { data: vestingInfo } = useSWR(
    [`StakeV2:vestingInfo:${active}`, chainId, readerAddress, "getVestingInfo", PLACEHOLDER_ACCOUNT],
    {
      fetcher: fetcher(undefined, Reader, [vesterAddresses]),
    }
  );

  const { equalPrice } = useEqualPrice(chainId, {}, active);

  let { total: equalSupply } = useTotalEqualSupply();

  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }

  const { balanceData, supplyData } = getBalanceAndSupplyData(walletBalances);
  const depositBalanceData = getDepositBalanceData(depositBalances);
  const stakingData = getStakingData(stakingInfo);
  const vestingData = getVestingData(vestingInfo);

  const processedData = getProcessedData(
    balanceData,
    supplyData,
    depositBalanceData,
    stakingData,
    vestingData,
    aum,
    nativeTokenPrice,
    stakedEqualSupply,
    equalPrice,
    equalSupply
  );

  return <>{`${formatKeyAmount(processedData, label, 2, 2, true)}%`}</>;
}
