import React, { useEffect } from "react";
import Footer from "../../Footer";
import { NavLink } from "react-router-dom";
import "./BuyEQUAL.css";

import Synapse from "../../img/Synapse.svg";
import Multichain from "../../img/Multichain.png";
import Celler from "../../img/cbridgev2.svg";
import XPol from "../../img/xpollinate.svg";
import logo from "../../img/logo-equal.png";
import equityIcon from '../../img/logo-equity.png'

export default function BuyEQUAL() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="BuyEQUAL">
      <div className="BuyEQUAL-container BuyEQUAL-container-pdsmallscreen default-container" style={{marginBottom:200}}>
        <div className="BuyEQUAL-title-section">
          <div className="BuyEQUAL-title">Buy EQUAL</div>
          <div className="BuyEQUALEQUITY-description">
            <p>
              You can participate in the platform earnings by buying and staking
              EQUAL
            </p>
            To buy EQUAL, ensure you are connected to the Fantom Network and
            have enough FTM for the gas fees.
            <br />
            <br />
            You can learn how to bridge FTM to Fantom Network by following
            this:{" "}
            <a
              href="https://docs.equalizer.exchange/faq#bridging"
              target="_blank"
              rel="noopener noreferrer"
              className="textcolorwhite"
            >
              tutorial
            </a>
            , Check the following alternative bridges for the best rates:
          </div>
          <div className="alternative-bridges">
            <a
              href="https://synapseprotocol.com/?inputCurrency=USDC&outputCurrency=USDC&outputChain=250"
              target="_blank"
              rel="noopener noreferrer"
              className="Synapse"
            >
              <img src={Synapse} alt="Synapse" />
            </a>
            <a
              href="https://app.multichain.org/#/router"
              target="_blank"
              rel="noopener noreferrer"
              className="Multichain"
            >
              <img src={Multichain} alt="Multichain" />
            </a>
          </div>
          <p className="BuyEQUALEQUITY-description">
            Learn more at{" "}
            <a
              href="https://docs.equalizer.exchange/tokenomics"
              target="_blank"
              rel="noopener noreferrer" className="textcolorwhite"
            >
             docs.equalizer.exchange/tokenomics
            </a>
          </p>
          <a
            href="https://equalizer.exchange/#/swap?outputCurrency=0x3Fd3A0c85B70754eFc07aC9Ac0cbBDCe664865A6"
            target="_blank"
            rel="noopener noreferrer"
            className="BuyEQUAL-purchase-block"
          >
            <div className="BuyEQUAL-purchase-block-icon">
              <img src={logo} alt="logo" style={{width: '53px', height: '53px'}} />
            </div>
            <div className="BuyEQUAL-purchase-block-info">
              <div className="BuyEQUAL-purchase-block-info__title">Buy EQUAL</div>
              <div className="BuyEQUAL-purchase-block-info__subtitle">
                Equalizer Fantom Network
              </div>
            </div>
          </a>
          <div className="BuyEQUALEQUITY-description">If you wish to provide liquidity for EQUITY instead, you can find more info at <a href="https://docs.equalizer.exchange/tokenomics/equity" className="textcolorwhite" target="_blank" rel="noopener noreferrer">docs.equalizer.exchange/tokenomics/equity</a>.</div>
          <div className="EQUITY-block-section ">
            <NavLink to="/buy_equity" className="EQUITY-block " >
              <div className="EQUITY-block-icon">
                <img src={equityIcon} alt="equityIcon" style={{width: '40px', height: '40px'}} />
              </div>
              <div className="EQUITY-block-label">Mint</div>
            </NavLink>
            <NavLink to="/buy_equity#redeem" className="EQUITY-block marginbottombyequal">
              <div className="EQUITY-block-icon">
                <img src={equityIcon} alt="equityIcon" height="40px" />
              </div>
              <div className="EQUITY-block-label">Redeem</div>
            </NavLink>
          </div>
        </div>
      </div>          
      <Footer />
    </div>
  );
}
