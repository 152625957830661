import React, { useCallback ,useEffect} from "react";
import Footer from "../../Footer";
import { Link, NavLink } from "react-router-dom";

import "./Home.css";

import liquidityIcon from "../../img/liquidation.png";
import EquityLogoText from "../../img/equity-logo-text.png";
import costIcon from "../../img/save-costs.png";
import simpleSwapIcon from "../../img/simple-swaps.png";

import fantomIcon from "../../img/lg_fantom.svg";

import tradingIcon from "../../img/total-volume.png";
import statsIcon from "../../img/openinterest.png";
import totaluserIcon from "../../img/total-users.png";




import useSWR from "swr";

import {
  formatAmount,
  bigNumberify,
  numberWithCommas,
  getServerUrl,
  USD_DECIMALS,
  useChainId,
  FTM,
  switchNetwork,
} from "../../Helpers";

import { useWeb3React } from "@web3-react/core";

import { useUserStat,useTotalVolume, usePositionStats } from "../../Api";

import TokenCard from "../../components/TokenCard/TokenCard";
import BlueWaves from '../../components/BlueWaves/BlueWaves';

export default function Home() {

  const { chainId } = useChainId();
  const { active } = useWeb3React();

  const fantomPositionStats = usePositionStats()

  const totalVolumeSum = useTotalVolume();

  // Open Interest

  let openInterest = bigNumberify(0);
  if (
    fantomPositionStats &&
    fantomPositionStats.totalLongPositionSizes &&
    fantomPositionStats.totalShortPositionSizes
  ) {
    openInterest = openInterest.add(fantomPositionStats.totalLongPositionSizes);
    openInterest = openInterest.add(fantomPositionStats.totalShortPositionSizes);
  }



  // user stat
  const fantomUserStats = useUserStat(FTM);

  let totalUsers = 0;

  if (fantomUserStats && fantomUserStats.uniqueCount) {
    totalUsers += fantomUserStats.uniqueCount;
  }



  const changeNetwork = useCallback(
    (network) => {
      if (network === chainId) {
        return;
      }
      if (!active) {
        setTimeout(() => {
          return switchNetwork(network, active);
        }, 500);
      } else {
        return switchNetwork(network, active);
      }
    },
    [chainId, active]
  );

  return (
    <div className="Home">
      <div className="Home-top">
        <div className="Home-title-section-container default-container">
          <div className="Home-title-section">
            <div className="Home-title">
            <span className="titletextcolor"><img
                      src={EquityLogoText}
                      style={{ width: "100%", height: "auto", maxWidth:"350px" }}
                      className="big"
                      alt="EQUAL Logo"
                    /></span>
            <br />
              <h1 className="home-tag-line">Amplifying Market Potential</h1>
            </div>
            <div className="Home-description">
            	<p>Boost your blue chips with up-to 30x leverage</p>
            </div>
            <NavLink activeClassName="active" to="/trade" className="default-btn2">
              LAUNCH APP
            </NavLink>
          </div>
        </div>
        <div className="Home-latest-info-container default-container">
          <div className="Home-latest-info-block" style={{borderRadius:'15px'}}>
            <img src={tradingIcon} style={{ width: '50px', height: '50px'}} alt="trading" className="Home-latest-info__icon" />
            <div className="Home-latest-info-content">
              <div className="Home-latest-info__title">Total Trading Volume</div>
              <div className="Home-latest-info__value">${formatAmount(totalVolumeSum, USD_DECIMALS, 0, true)}</div>
            </div>
          </div>
          <div className="Home-latest-info-block" style={{borderRadius:'15px'}}>
            <img src={statsIcon} style={{ width: '50px', height: '50px'}} alt="trading" className="Home-latest-info__icon" />
            <div className="Home-latest-info-content">
              <div className="Home-latest-info__title">Open Interest</div>
              <div className="Home-latest-info__value">${formatAmount(openInterest, USD_DECIMALS, 0, true)}</div>
            </div>
          </div>
          <div className="Home-latest-info-block" style={{borderRadius:'15px'}}>
            <img src={totaluserIcon} style={{ width: '50px', height: '50px'}} alt="trading" className="Home-latest-info__icon" />
            <div className="Home-latest-info-content">
              <div className="Home-latest-info__title">Total Users</div>
              <div className="Home-latest-info__value">{numberWithCommas(totalUsers.toFixed(0))}</div>
            </div>
          </div>
        </div>
        <div className="blue-waves">
          <BlueWaves />
        </div>
      </div>
      <div className="Home-benefits-section">
        <div className="Home-benefits default-container">
          <div className="Home-benefit">
            <div className="Home-benefit-icon">
              <img src={liquidityIcon} alt="liquidity" className="Home-benefit-icon-symbol" width={70} />
              <div className="Home-benefit-title">Protection Against Market Volatility</div>
            </div>
            <div className="Home-benefit-description">
             <p>We use data from a mixture of high quality & reliable price feeds to decide when to liquidate assets, providing an extra layer of security against short-lived market dips.</p>
            </div>
          </div>
          <div className="Home-benefit">
            <div className="Home-benefit-icon">
              <img src={costIcon} alt="cost" className="Home-benefit-icon-symbol" width={70} />
              <div className="Home-benefit-title">Efficient Trading</div>
            </div>
            <div className="Home-benefit-description">
              <p>Trade assets with minimal difference in buying and selling prices and no hidden impact on price. We aim to offer the best available pricing without any unexpected costs.</p>
            </div>
          </div>
          <div className="Home-benefit">
            <div className="Home-benefit-icon">
              <img src={simpleSwapIcon} alt="simpleswap" className="Home-benefit-icon-symbol" width={70} />
              <div className="Home-benefit-title">Simplified Trading Process</div>
            </div>
            <div className="Home-benefit-description">
              <p>Our user-friendly interface facilitates smooth swap operations. You can easily swap from any supported asset to the position of your choice.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="Home-cta-section">
        <div className="Home-cta-container default-container">
          <div className="Home-cta-info">
            <div className="Home-cta-info__title">Exclusively on Fantom Opera</div>
            <div className="Home-cta-info__description"><p>Designed to fully capitalize on the potential of veEqual, and turbocharged by the Fantom network.</p></div>
            <NavLink activeClassName="active" to="/trade" className="default-btn2">
              LAUNCH APP
            </NavLink>
          </div>
          <div className="Home-cta-options homepage-container-pd">
            <div className="Home-cta-option Home-cta-option-fantom">
              <div className="Home-cta-option-info">
              <div className="fantomCoin"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Home-token-card-section mb-5">
        <div className="Home-token-card-container default-container">
          <div className="Home-token-card-info">
            <div className="Home-token-card-info__title">Unveiling the Core of our Equosystem</div>
          </div>
          <TokenCard />
        </div>
      </div>

      <Footer />
    </div>
  );
}
