import { ethers } from "ethers";
import { getContract } from "../Addresses";

const TOKENS = {
  250: [
    // fantom
    {
      name: "Fantom",
      symbol: "FTM",
      decimals: 18,
      address: ethers.constants.AddressZero,
      coingeckoUrl: "https://www.coingecko.com/en/coins/fantom",
      isNative: true,
      isShortable: true,
      displayDecimals:4
    },
    {
      name: "Wrapped Fantom",
      symbol: "WFTM",
      decimals: 18,
      address: "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83",
      coingeckoUrl: "https://www.coingecko.com/en/coins/wrapped-fantom",
      isWrapped: true,
      baseSymbol: "FTM",
      displayDecimals:4
    },
    {
      name: "Ethereum",
      symbol: "ETH",
      address: "0x74b23882a30290451A17c44f4F05243b6b58C76d",
      coingeckoUrl: "https://www.coingecko.com/en/coins/ethereum",
      decimals: 18,
      isShortable: true,
      displayDecimals:2
    },
    // {
    //   name: "Bitcoin",
    //   symbol: "BTC",
    //   address: "0x321162Cd933E2Be498Cd2267a90534A804051b11",
    //   coingeckoUrl: "https://www.coingecko.com/en/coins/bitcoin",
    //   decimals: 8,
    //   isShortable: true,
    //   displayDecimals:2
    // },
    {
      name: "USDC",
      symbol: "USDC",
      address: "0x04068da6c83afcfa0e13ba15a6696662335d5b75",
      coingeckoUrl: "https://www.coingecko.com/en/coins/usd-coin",
      decimals: 6,
      isStable: true,
      displayDecimals:4
    },
    // {
    //   name: "USDT",
    //   symbol: "USDT",
    //   address: "0x049d68029688eabf473097a2fc38ef61633a3c7a",
    //   coingeckoUrl: "https://www.coingecko.com/en/coins/tether",
    //   decimals: 6,
    //   isStable: true,
    //   displayDecimals:4
    // },
    // {
    //   name: "Dai",
    //   symbol: "DAI",
    //   address: "0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e",
    //   coingeckoUrl: "https://www.coingecko.com/en/coins/dai",
    //   decimals: 18,
    //   isStable: true,
    //   displayDecimals:4
    // },
  ],
};

export const CHAIN_FEED_IDS = {
  250: {
    FTM_USD: "0xf4766552d15ae4d256ad41b6cf2933482b0680dc", // done fantom
    ETH_USD: "0x11ddd3d147e5b83d01cee7070027092397d63658", // done fantom
    // BTC_USD: "0x8e94c22142f4a64b99022ccdd994f4e9ec86e4b4", // done fantom
    USDC_USD: "	0x2553f4eeb82d5a26427b8d1106c51499cba5d99c", // done fantom
    // DAI_USD: "0x91d5defaffe2854c7d02f50c80fa1fdc8a721e52", // done fantom
    // USDT_USD: "0xf64b636c5dfe1d3555a847341cdc449f612307d0", // done fantom
  },
};

const ADDITIONAL_TOKENS = {
  250: [
    {
      name: "EQUAL",
      symbol: "EQUAL",
      address: getContract(250, "EQUAL"),
      decimals: 18,
    },
    {
      name: "Escrowed EQUAL",
      symbol: "esEQUAL",
      address: getContract(250, "ES_EQUAL"),
      decimals: 18,
    },
    {
      name: "EQUAL LP",
      symbol: "EQUITY",
      address: getContract(250, "EQUITY"),
      decimals: 18,
    },
  ],
};

const CHAIN_IDS = [250];

const TOKENS_MAP = {};
const TOKENS_BY_SYMBOL_MAP = {};

for (let j = 0; j < CHAIN_IDS.length; j++) {
  const chainId = CHAIN_IDS[j];
  TOKENS_MAP[chainId] = {};
  TOKENS_BY_SYMBOL_MAP[chainId] = {};
  let tokens = TOKENS[chainId];
  if (ADDITIONAL_TOKENS[chainId]) {
    tokens = tokens.concat(ADDITIONAL_TOKENS[chainId]);
  }

  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i];
    TOKENS_MAP[chainId][token.address] = token;
    TOKENS_BY_SYMBOL_MAP[chainId][token.symbol] = token;
  }
}

const WRAPPED_TOKENS_MAP = {};
const NATIVE_TOKENS_MAP = {};
for (const chainId of CHAIN_IDS) {
  for (const token of TOKENS[chainId]) {
    if (token.isWrapped) {
      WRAPPED_TOKENS_MAP[chainId] = token;
    } else if (token.isNative) {
      NATIVE_TOKENS_MAP[chainId] = token;
    }
  }
}

export function getWrappedToken(chainId) {
  return WRAPPED_TOKENS_MAP[chainId];
}

export function getNativeToken(chainId) {
  return NATIVE_TOKENS_MAP[chainId];
}

export function getTokens(chainId) {
  return TOKENS[chainId];
}

export function isValidToken(chainId, address) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  return address in TOKENS_MAP[chainId];
}

export function getToken(chainId, address) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  if (!TOKENS_MAP[chainId][address]) {
    localStorage.removeItem("Exchange-token-selection-v2");
    localStorage.removeItem("BuyEquity-swap-token-address");
    window.location.reload();
  }
  return TOKENS_MAP[chainId][address];
}

export function getTokenBySymbol(chainId, symbol) {
  const token = TOKENS_BY_SYMBOL_MAP[chainId][symbol];
  if (!token) {
    throw new Error(`Incorrect symbol "${symbol}" for chainId ${chainId}`);
  }
  return token;
}

export function getWhitelistedTokens(chainId) {
  return TOKENS[chainId].filter((token) => token.symbol !== "USDE");
}
