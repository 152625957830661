const CONTRACTS = {
  250: {
    // fantom
    Vault: "0x9e4105F9E2284532474f69e65680e440F4C91cb8", 
    Router: "0xd311Fd89e8403c2E90593457543E99cECc70D511", 
    VaultReader: "0x7e2EA31ec55225731C59f720CC89A9A26eF7aa48", 
    Reader: "0x88171375F6236885f463341d001B419D477eDB74", 
    EquityManager: "0x82f59f4a17f20Da83D5021e1D194323c5E375aC0", 
    RewardRouter: "0x855FaC135Ba4D7bc79fdbE084dE255E87FA79237", 
    RewardReader: "0x3e8B14B5534333A2B83a31d778ec3bCd9dc946f4", 
    EQUITY: "0x8B3E5eFaA3eA8e875112c6464218c28Db8F0b4d9", 
    EQUAL: "0x3Fd3A0c85B70754eFc07aC9Ac0cbBDCe664865A6", // done
    ES_EQUAL: "0x5A420F4364B8C046d7134d5512a65151D4Db1413", 
    BN_EQUAL: "0x24078e05EC0056539551d0e111c3660825919a5e", 
    EquityToken: "0x180DA8bD91406Dd41F18b6200C53dEb553f86180", 
    EquityFarm: "0xcc82bff654433c5674945c10ab0808863d4c8c48", 
    USDE: "0x6e3E0AF313933c27949BE9B5a3C6BC98e2860bB2", 
    StakedEqualTracker: "0x70f752c2EC0765c96996CA5fAA2cb8A5DD0E9057", 
    BonusEqualTracker: "0x1128d6237124BdD54675F10393FC76DA071a2306", 
    FeeEqualTracker: "0xa5bDF77FBa489512903c7683DC605B61a1235692", 
    StakedEquityTracker: "0x1f2A3023339995d9e04971F5b01941351D9f220f", 
    FeeEquityTracker: "0xBb061C7Dc70B852335f6F61764adEC930DC76Ebe", 
    StakedEqualDistributor: "0xA25D60cAF6dA44c889005101f128Df68f6aC677c", 
    StakedEquityDistributor: "0xBF2e43b6104171d221a22666517Da7F6f939018c", 
    EqualVester: "0x4bC0bc6Cb6B315ce993b983D6bd02BD6D211C929", 
    EquityVester: "0x0890F307663b11828454a6a80b56b5940e87b99E", 
    OrderBook: "0xA83F31aF44e812d2EdF0536516e7D274cd7301B8", 
    PositionManager: "0xCD9aFEab223ed4aCcE3E12E010932982E8368Fbc", 
    OrderBookReader: "0x8f02357cb55DbAd26DF5a7558CD810D5D0f05f43", 
    PositionRouter: "0xf2BfB9cA6e21b30034b9d56Cb4735d2c180cC7e1", 
    ReferralStorage: "0x9463b9c8140Ecfe1A7c13Fdf75B922F118C6671d", 
    ReferralReader: "0x83f0Dc8Aca861f42A2DFaAD93276A2A180c222EE",
    UniswapEqualUsdcPool: "0x76fa7935a5AFEf7fefF1C88bA858808133058908", // done
    NATIVE_TOKEN: "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83", // DONE
  },
};

const tryRequire = (path) => {
  try {
    return require(`${path}`);
  } catch (err) {
    return undefined;
  }
};
const devAddresses = tryRequire("./development.Addresses.js");

export function getContract(chainId, name) {
  const contracts = process.env.NODE_ENV === "development" && devAddresses ? devAddresses.CONTRACTS : CONTRACTS;

  if (!contracts[chainId]) {
    throw new Error(`Unknown chainId ${chainId}`);
  }
  if (!contracts[chainId][name]) {
    throw new Error(`Unknown constant "${name}" for chainId ${chainId}`);
  }
  return contracts[chainId][name];
}

export const XGMT_EXCLUDED_ACCOUNTS = [
  "0x330eef6b9b1ea6edd620c825c9919dc8b611d5d5",
  "0xd9b1c23411adbb984b1c4be515fafc47a12898b2",
  "0xa633158288520807f91ccc98aa58e0ea43acb400",
  "0xffd0a93b4362052a336a7b22494f1b77018dd34b",
];

export const EQUAL_MULTISIG_ADDRESS = "0xC424C343554aFd6CD270887D4232765850f5e93F";
export const MVD_TREASURY_ADDRESS = "0xC424C343554aFd6CD270887D4232765850f5e93F";
