import React, { useState, useEffect } from "react";
import './WarningBanner.css';

const WarningBanner = ({ children }) => {
  return (
    <div className="warning-banner">
        <p>Due to the recent <a href="https://www.coindesk.com/business/2023/07/06/multichain-bridges-experience-unannounced-outflows-of-over-130m-in-crypto/" target="_BLANK"> developments at  multichain</a> - We recommend all users to withdraw their EQUITY position immediately and derisk from USDC and WETH</p>
      {children}
    </div>
  );
};

export default WarningBanner;