import { BigNumber, ethers } from "ethers";
import { gql } from "@apollo/client";
import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import useSWR from "swr";

import OrderBook from "../abis/OrderBook.json";
import PositionManager from "../abis/PositionManager.json";
import Vault from "../abis/Vault.json";
import Router from "../abis/Router.json";
import UniPool from "../abis/UniPool.json";
import Token from "../abis/Token.json";
import InfoSupply from "../abis/InfoSupply.json";
import VeEqual from "../abis/VeEqual.json";
import VaultReader from "../abis/VaultReader.json";
import EquityFarm from "../abis/EquityFarm.json";
import ReferralStorage from "../abis/ReferralStorage.json";

import { getContract, MVD_TREASURY_ADDRESS, EQUAL_MULTISIG_ADDRESS, TREASURY_ADDRESS } from "../Addresses";
import { getConstant } from "../Constants";
import {
  UI_VERSION,
  // DEFAULT_GAS_LIMIT,
  bigNumberify,
  getExplorerUrl,
  getServerBaseUrl,
  getServerUrl,
  setGasPrice,
  getGasLimit,
  replaceNativeTokenAddress,
  getProvider,
  getOrderKey,
  fetcher,
  expandDecimals,
  getInfoTokens,
  isAddressZero,
  helperToast,
  FTM,
  FIRST_DATE_TS,
  PLACEHOLDER_ACCOUNT,
  formatAmount
} from "../Helpers";
import { getTokens, getWhitelistedTokens } from "../data/Tokens";

import { fantomGraphClient, positionsGraphClient } from "./common";
import { parseUnits } from "@ethersproject/units";
export * from "./prices";

const { AddressZero } = ethers.constants;

function getEqualGraphClient(chainId) {
  if (chainId === FTM) {
    return fantomGraphClient;
  }
  throw new Error(`Unsupported chain ${chainId}`);
}

export function useAllOrdersStats(chainId) {
  const query = gql(`{
    orderStat(id: "total") {
      openSwap
      openIncrease
      openDecrease
      executedSwap
      executedIncrease
      executedDecrease
      cancelledSwap
      cancelledIncrease
      cancelledDecrease
    }
  }`);

  const [res, setRes] = useState();

  useEffect(() => {
    getEqualGraphClient(chainId).query({ query }).then(setRes).catch(console.warn);
  }, [setRes, query, chainId]);

  return res ? res.data.orderStat : null;
}

export function useInfoTokens(library, chainId, active, tokenBalances, fundingRateInfo, vaultPropsLength) {
  const tokens = getTokens(chainId);
  const vaultReaderAddress = getContract(chainId, "VaultReader");
  const vaultAddress = getContract(chainId, "Vault");
  const positionRouterAddress = getContract(chainId, "PositionRouter");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");

  const whitelistedTokens = getWhitelistedTokens(chainId);
  const whitelistedTokenAddresses = whitelistedTokens.map((token) => token.address);

  console.log("getVaultTokenInfoV3 ", vaultAddress, vaultReaderAddress, positionRouterAddress, nativeTokenAddress, whitelistedTokenAddresses)
  const { data: vaultTokenInfo } = useSWR(
    [`useInfoTokens:${active}`, chainId, vaultReaderAddress, "getVaultTokenInfoV3"],
    {
      fetcher: fetcher(library, VaultReader, [
        vaultAddress,
        positionRouterAddress,
        nativeTokenAddress,
        expandDecimals(1, 18),
        whitelistedTokenAddresses,
      ]),
    }
  );

  const indexPricesUrl = getServerUrl(chainId, "/prices");
  // const { data: indexPrices } = useSWR([indexPricesUrl], {
  //   fetcher: (...args) => fetch(...args).then((res) => res.json()),
  //   refreshInterval: 500,
  //   refreshWhenHidden: true,
  // });

  const indexPrices = [];

  return {
    infoTokens: getInfoTokens(
      tokens,
      tokenBalances,
      whitelistedTokens,
      vaultTokenInfo,
      fundingRateInfo,
      vaultPropsLength,
      indexPrices,
      nativeTokenAddress
    ),
  };
}

export function useUserStat(chainId) {
  const query = gql(`{
    userStat(id: "total") {
      id
      uniqueCount
    }
  }`);

  const [res, setRes] = useState();

  useEffect(() => {
    getEqualGraphClient(chainId).query({ query }).then(setRes).catch(console.warn);
  }, [setRes, query, chainId]);

  return res ? res.data.userStat : null;
}

export function useLiquidationsData(chainId, account) {
  const [data, setData] = useState(null);
  useEffect(() => {
    if (account) {
      const query = gql(`{
         liquidatedPositions(
           where: {account: "${account.toLowerCase()}"}
           first: 100
           orderBy: timestamp
           orderDirection: desc
         ) {
           key
           timestamp
           borrowFee
           loss
           collateral
           size
           markPrice
           type
         }
      }`);
      const graphClient = getEqualGraphClient(chainId);
      graphClient
        .query({ query })
        .then((res) => {
          const _data = res.data.liquidatedPositions.map((item) => {
            return {
              ...item,
              size: bigNumberify(item.size),
              collateral: bigNumberify(item.collateral),
              markPrice: bigNumberify(item.markPrice),
            };
          });
          setData(_data);
        })
        .catch(console.warn);
    }
  }, [setData, chainId, account]);

  return data;
}

export function useAllPositions(chainId, library) {
  const count = 1000;
  const query = gql(`{
    aggregatedTradeOpens(
      first: ${count}
    ) {
      account
      initialPosition{
        indexToken
        collateralToken
        isLong
        sizeDelta
      }
      increaseList {
        sizeDelta
      }
      decreaseList {
        sizeDelta
      }
    }
  }`);

  const [res, setRes] = useState();

  useEffect(() => {
    positionsGraphClient.query({ query }).then(setRes).catch(console.warn);
  }, [setRes, query]);

  const key = res ? `allPositions${count}__` : false;
  const { data: positions = [] } = useSWR(key, async () => {
    const provider = getProvider(library, chainId);
    const vaultAddress = getContract(chainId, "Vault");
    const contract = new ethers.Contract(vaultAddress, Vault.abi, provider);
    const ret = await Promise.all(
      res.data.aggregatedTradeOpens.map(async (dataItem) => {
        try {
          const { indexToken, collateralToken, isLong } = dataItem.initialPosition;
          const positionData = await contract.getPosition(dataItem.account, collateralToken, indexToken, isLong);
          const position = {
            size: bigNumberify(positionData[0]),
            collateral: bigNumberify(positionData[1]),
            entryFundingRate: bigNumberify(positionData[3]),
            account: dataItem.account,
          };
          position.fundingFee = await contract.getFundingFee(collateralToken, position.size, position.entryFundingRate);
          position.marginFee = position.size.div(1000);
          position.fee = position.fundingFee.add(position.marginFee);

          const THRESHOLD = 5000;
          const collateralDiffPercent = position.fee.mul(10000).div(position.collateral);
          position.danger = collateralDiffPercent.gt(THRESHOLD);

          return position;
        } catch (ex) {
          console.error(ex);
        }
      })
    );

    return ret.filter(Boolean);
  });

  return positions;
}

export function useAllOrders(chainId, library) {
  const query = gql(`{
    orders(
      first: 1000,
      orderBy: createdTimestamp,
      orderDirection: desc,
      where: {status: "open"}
    ) {
      type
      account
      index
      status
      createdTimestamp
    }
  }`);

  const [res, setRes] = useState();

  useEffect(() => {
    getEqualGraphClient(chainId).query({ query }).then(setRes);
  }, [setRes, query, chainId]);

  const key = res ? res.data.orders.map((order) => `${order.type}-${order.account}-${order.index}`) : null;
  const { data: orders = [] } = useSWR(key, () => {
    const provider = getProvider(library, chainId);
    const orderBookAddress = getContract(chainId, "OrderBook");
    const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, provider);
    return Promise.all(
      res.data.orders.map(async (order) => {
        try {
          const type = order.type.charAt(0).toUpperCase() + order.type.substring(1);
          const method = `get${type}Order`;
          const orderFromChain = await contract[method](order.account, order.index);
          const ret = {};
          for (const [key, val] of Object.entries(orderFromChain)) {
            ret[key] = val;
          }
          if (order.type === "swap") {
            ret.path = [ret.path0, ret.path1, ret.path2].filter((address) => address !== AddressZero);
          }
          ret.type = type;
          ret.index = order.index;
          ret.account = order.account;
          ret.createdTimestamp = order.createdTimestamp;
          return ret;
        } catch (ex) {
          console.error(ex);
        }
      })
    );
  });

  return orders.filter(Boolean);
}

export function usePositionsForOrders(chainId, library, orders) {
  const key = orders ? orders.map((order) => getOrderKey(order) + "____") : null;
  const { data: positions = {} } = useSWR(key, async () => {
    const provider = getProvider(library, chainId);
    const vaultAddress = getContract(chainId, "Vault");
    const contract = new ethers.Contract(vaultAddress, Vault.abi, provider);
    const data = await Promise.all(
      orders.map(async (order) => {
        try {
          const position = await contract.getPosition(
            order.account,
            order.collateralToken,
            order.indexToken,
            order.isLong
          );
          if (position[0].eq(0)) {
            return [null, order];
          }
          return [position, order];
        } catch (ex) {
          console.error(ex);
        }
      })
    );
    return data.reduce((memo, [position, order]) => {
      memo[getOrderKey(order)] = position;
      return memo;
    }, {});
  });

  return positions;
}

function invariant(condition, errorMsg) {
  if (!condition) {
    throw new Error(errorMsg);
  }
}

export function useTradesFromGraph(chainId, account) {
  const [trades, setTrades] = useState();
  const [reload, setReload] = useState(1);
  const updateTrades = ()=>{setReload(reload+1)}



  useEffect(() => {
    
    const queryString = account && account.length > 0 ? `where : { account: "${account.toLowerCase()}"}` : ``;
    const query = gql(`{
      actionDatas ( orderBy: timestamp orderDirection: desc first:50 ${queryString} ) {
        id
        action
        account
        txhash
        blockNumber
        timestamp
        params
      }
    }`);

    // fix for non account queries slow load times
    if(queryString){
      setInterval(()=>getEqualGraphClient(chainId).query({ query }).then(setTrades),1000)
    }else{
      getEqualGraphClient(chainId).query({ query }).then(setTrades)
    }

  }, [setTrades, chainId, account, reload]);

  return { trades, updateTrades };
}

export function useTrades(chainId, account) {
  const url =
    account && account.length > 0
      ? `${getServerBaseUrl(chainId)}/actions?account=${account}`
      : `${getServerBaseUrl(chainId)}/actions`;
  const { data: trades, mutate: updateTrades } = useSWR(url, {
    dedupingInterval: 30000,
    fetcher: (...args) => fetch(...args).then((res) => res.json()),
  });

  if (trades) {
    trades.sort((item0, item1) => {
      const data0 = item0.data;
      const data1 = item1.data;
      const time0 = parseInt(data0.timestamp);
      const time1 = parseInt(data1.timestamp);
      if (time1 > time0) {
        return 1;
      }
      if (time1 < time0) {
        return -1;
      }

      const block0 = parseInt(data0.blockNumber);
      const block1 = parseInt(data1.blockNumber);

      if (isNaN(block0) && isNaN(block1)) {
        return 0;
      }

      if (isNaN(block0)) {
        return 1;
      }

      if (isNaN(block1)) {
        return -1;
      }

      if (block1 > block0) {
        return 1;
      }

      if (block1 < block0) {
        return -1;
      }

      return 0;
    });
  }

  return { trades, updateTrades };
}

export function useStakedEqualSupply(library, active) {
  const equalAddress = getContract(FTM, "EQUAL");
  const stakedEqualTrackerAddress = getContract(FTM, "StakedEqualTracker");

  const { data, mutate } = useSWR(
    [`StakeV2:stakedEqualSupply:${active}`, FTM, equalAddress, "balanceOf", stakedEqualTrackerAddress],
    {
      fetcher: fetcher(library, Token),
    }
  );

  return { data, mutate };
}

export function useMvdEqualTreasuryHoldings() {
  const { data: equalHoldings, mutate: updateEqualTreasuryHoldings } = useSWR(
    [`EQUAL:equalTreasuryHoldings:${FTM}`, FTM, getContract(FTM, "EQUAL"), "balanceOf"],
    {
      fetcher: fetcher(undefined, Token, [MVD_TREASURY_ADDRESS]),
    }
  );

  return {
    data: equalHoldings ? bigNumberify(equalHoldings) : undefined,
    mutate: updateEqualTreasuryHoldings,
  };
}

export function useEqualMultisigHoldings() {
  const { data: equalHoldings, mutate: updateEqualTreasuryHoldings } = useSWR(
    [`EQUAL:equalMultisigHoldings:${FTM}`, FTM, getContract(FTM, "EQUAL"), "balanceOf"],
    {
      fetcher: fetcher(undefined, Token, [EQUAL_MULTISIG_ADDRESS]),
    }
  );

  return {
    data: equalHoldings ? bigNumberify(equalHoldings) : undefined,
    mutate: updateEqualTreasuryHoldings,
  };
}

export function useMvdEquityTreasuryHoldings() {
  const { data: equityHoldings, mutate: updateEquityTreasuryHoldings } = useSWR(
    [`EQUITY:equityTreasuryHoldings:${FTM}`, FTM, getContract(FTM, "StakedEquityTracker"), "balanceOf"],
    {
      fetcher: fetcher(undefined, Token, [MVD_TREASURY_ADDRESS]),
    }
  );

  return {
    data: equityHoldings ? bigNumberify(equityHoldings) : undefined,
    mutate: updateEquityTreasuryHoldings,
  };
}

// export function useProtocolOwnLiquidity() {
//   const { data: protocolOwnLiquidity, mutate: updateProtocolOwnLiquidity } = useSWR(
//     [`ProtocolOwnLiquidity:${FTM}`, FTM, getContract(FTM, "StakedEquityTracker"), "balanceOf"],
//     {
//       fetcher: fetcher(undefined, Token, [EQUAL_MULTISIG_ADDRESS]),
//     }
//   );

//   return {
//     data: protocolOwnLiquidity ? bigNumberify(protocolOwnLiquidity) : undefined,
//     mutate: updateProtocolOwnLiquidity,
//   };
// }

export function useProtocolOwnLiquidity() {
  const { data: protocolOwnLiquidity, mutate: updateProtocolOwnLiquidity } = useSWR(
    [`ProtocolOwnLiquidity:${FTM}`, FTM, getContract(FTM, "StakedEquityTracker"), "balanceOf"],
    {
      fetcher: fetcher(undefined, Token, [EQUAL_MULTISIG_ADDRESS]),
    }
  );

  return {
    data: protocolOwnLiquidity ? bigNumberify(protocolOwnLiquidity) : undefined,
    mutate: updateProtocolOwnLiquidity,
  };
}

export function useHasOutdatedUi() {
  // const url = getServerUrl(FTM, "/ui_version");
  // const { data, mutate } = useSWR([url], {
  //   fetcher: (...args) => fetch(...args).then((res) => res.text()),
  // });

  // let hasOutdatedUi = false;

  // if (data && parseFloat(data) > parseFloat(UI_VERSION)) {
  //   hasOutdatedUi = true;
  // }

  return { data: false };
}


export function useEqualPrice(libraries, active) {
  const fantomLibrary = libraries && libraries.fantom ? libraries.fantom : undefined;
  const { data: equalPrice, mutate: mutateFromFantom } = useEqualPriceFromFantom(fantomLibrary, active);

  const mutate = useCallback(() => {
    mutateFromFantom();
  }, [mutateFromFantom]);

  return {
    equalPrice,
    mutate,
  };
}

export function useTotalEquityTokenSupply() {
  const { data: equityTokenTotalSupply, mutate: updateEquityTokenTotalSupply } = useSWR(
    [`EquityToken:totalEqualSupply:${FTM}`, FTM, getContract(FTM, "EquityToken"), "totalSupply"],
    {
      fetcher: fetcher(undefined, Token),
    }
  );

  return {
    total: equityTokenTotalSupply ? bigNumberify(equityTokenTotalSupply) : undefined,
    mutate: updateEquityTokenTotalSupply,
  };
}

export function useTotalEqualSupply() {
  const { data: equalTotalSupply, mutate: updateEqualTotalSupply } = useSWR(
    [`EQUAL:totalEqualSupply:${FTM}`, FTM, getContract(FTM, "EQUAL"), "totalSupply"],
    {
      fetcher: fetcher(undefined, Token),
    }
  );

  const { data: infoContractData, mutate: updateInfoContractData } = useSWR(
    [`EQUAL:totalEqualSupply:${FTM}`, FTM, "0x3a603eCEAE046828FEBBcbd097bF97Adc23DC072", "info"],
    {
      fetcher: fetcher(undefined, InfoSupply),
    }
  );

  const mutate = useCallback(() => {
    updateInfoContractData();
    updateEqualTotalSupply();
  }, [updateInfoContractData, updateEqualTotalSupply]);

  return {
    total: equalTotalSupply ? bigNumberify(equalTotalSupply) : undefined,
    outstandingSupply: infoContractData ? infoContractData[2] : undefined,
    mutate,
  };
}

export function useTotalVEEqualSupply() {
  const { data: equalTotalSupply, mutate: updateEqualTotalSupply } = useSWR(
    [`EQUAL:totalveEqualSupply:${FTM}`, FTM, getContract(FTM, "EQUAL"), "balanceOf"],
    {
      fetcher: fetcher(undefined, Token, ["0x8313f3551C4D3984FfbaDFb42f780D0c8763Ce94"]),
    }
  );

  return {
    total: equalTotalSupply ? bigNumberify(equalTotalSupply) : undefined,
    mutate: updateEqualTotalSupply,
  };
}

export function useTotalEquityTokenStakedFarm(account) {
  const equityFarmAddress = getContract(FTM, "EquityFarm");

  const { data: equityTokenStakedSupply, mutate: updateEquityTokenStakedSupply } = useSWR(
    [
      `FarmEquity:stakedEqualSupply:${FTM}`,
      FTM,
      getContract(FTM, "EquityFarm"),
      "totalSupply",
    ],
    {
      fetcher: fetcher(undefined, EquityFarm),
    }
  );

  
  const { data: equityTokenStakedSupplyDollars, mutate: updateEquityTokenStakedSupplyDollars } = useSWR(
    [
      `FarmEquity:stakedEqualSupply:${FTM}`,
      FTM,
      getContract(FTM, "EquityFarm"),
      "tvlDeposits",
    ],
    {
      fetcher: fetcher(undefined, EquityFarm),
    }
    );
  console.log(equityTokenStakedSupply?.toString(), equityTokenStakedSupplyDollars?.toString(), "STAKED SUPP")
    
  const { data: farmAprToken1, mutate: updateFarmAprToken1 } = useSWR(
    [
      `FarmEquity:farmAprToken1:${FTM}`,
      FTM,
      getContract(FTM, "EquityFarm"),
      "apr",
      0,
    ],
    {
      fetcher: fetcher(undefined, EquityFarm),
    }
  );

  // const { data: farmAprToken2, mutate: updateFarmAprToken2 } = useSWR(
  //   [
  //     `FarmEquity:farmAprToken2:${FTM}`,
  //     FTM,
  //     getContract(FTM, "EquityFarm"),
  //     "apr",
  //     1,
  //   ],
  //   {
  //     fetcher: fetcher(undefined, EquityFarm),
  //   }
  // );

  const { data: splitRatio, mutate: updateSplitRatio } = useSWR(
    [
      `FarmEquity:splitRatio:${FTM}`,
      FTM,
      getContract(FTM, "EquityFarm"),
      "splitRatio",
    ],
    {
      fetcher: fetcher(undefined, EquityFarm),
    }
  );

  const rewardAddress1 = "0x3Fd3A0c85B70754eFc07aC9Ac0cbBDCe664865A6";

  const { data: earnedToken1, mutate: updateEarnedToken1 } = useSWR(
    [
      `FarmEquity:earnedToken1:${FTM}`,
      FTM,
      getContract(FTM, "EquityFarm"),
      "earned",
      rewardAddress1, account
    ],
    {
      fetcher: fetcher(undefined, EquityFarm),
    }
  );



  const mutate = useCallback(() => {
    updateEquityTokenStakedSupply();
    updateEquityTokenStakedSupplyDollars();
    updateSplitRatio();
    updateFarmAprToken1();
    updateEarnedToken1();
  }, [updateEquityTokenStakedSupply, updateEquityTokenStakedSupplyDollars, updateFarmAprToken1, updateSplitRatio, updateEarnedToken1]);

  
  let _aprT1 = Number(formatAmount(farmAprToken1, 18, 4, false))
  let _eT1 = Number(formatAmount(earnedToken1, 18, 4, false))
  let _sR = Number(formatAmount(splitRatio, 6, 4, false))
  console.log("INFF", _eT1 - (_eT1 * (_sR)))
  return {
    totalStakedFarm: equityTokenStakedSupply,
    totalStakedFarmDollars: equityTokenStakedSupplyDollars,
    farmAprToken1: farmAprToken1 === undefined || splitRatio === undefined ? 0 : parseUnits((_aprT1 - (_aprT1 * (_sR))).toFixed(18)),
    farmAprToken2: farmAprToken1 === undefined || splitRatio === undefined ? 0 : parseUnits((_aprT1 * (_sR)).toFixed(18)),
    earnedToken1: earnedToken1 === undefined || splitRatio === undefined ? 0 : parseUnits((_eT1 - (_eT1 * (_sR))).toFixed(18)),
    earnedToken2: earnedToken1 === undefined || splitRatio === undefined ? 0 : parseUnits((_eT1 * (_sR)).toFixed(18)),
    mutate,
  };
}

export function useTotalEqualStaked() {
  const stakedEqualTrackerAddressFantom = getContract(FTM, "StakedEqualTracker");
  let totalStakedEqual = useRef(bigNumberify(0));
  const { data: stakedEqualSupplyFantom, mutate: updateStakedEqualSupplyFantom } = useSWR(
    [
      `StakeV2:stakedEqualSupply:${FTM}`,
      FTM,
      getContract(FTM, "EQUAL"),
      "balanceOf",
      stakedEqualTrackerAddressFantom,
    ],
    {
      fetcher: fetcher(undefined, Token),
    }
  );

  const mutate = useCallback(() => {
    updateStakedEqualSupplyFantom();
  }, [updateStakedEqualSupplyFantom]);

  if (stakedEqualSupplyFantom) {
    let total = bigNumberify(stakedEqualSupplyFantom);
    totalStakedEqual.current = total;
  }

  return {
    fantom: stakedEqualSupplyFantom,
    total: totalStakedEqual.current,
    mutate,
  };
}

export function useTotalEqualExcludedFromSupply() {
  const stakedEqualTrackerAddressFantom = getContract(FTM, "StakedEqualTracker");
  const wally1VesterAddress = "0xC424C343554aFd6CD270887D4232765850f5e93F" // dao fund
  let totalStakedEqual = useRef(bigNumberify(0));

  const { data: totalWally1StoredEqual, mutate: updateTotalWally1StoredEqual } = useSWR(
    [
      `StakeV2:totalWally1StoredEqual:${FTM}`,
      FTM,
      getContract(FTM, "EQUAL"),
      "balanceOf",
      wally1VesterAddress,
    ],
    {
      fetcher: fetcher(undefined, Token),
    }
  );

  const mutate = useCallback(() => {
    updateTotalWally1StoredEqual();
  }, [
    updateTotalWally1StoredEqual,]);

  if (
   
    totalWally1StoredEqual
    ) {
    let total_7 = bigNumberify(totalWally1StoredEqual);
    totalStakedEqual.current = total_7;
  }

  return {
    total: totalStakedEqual.current ? totalStakedEqual.current : bigNumberify(0),
    mutate,
  };
}

export function useTotalEqualInLiquidity() {
  let poolAddressFantom = getContract(FTM, "UniswapEqualUsdcPool");
  // let poolAddressFantom = getContract(FTM, "UniswapEqualUsdcPool");

  let totalEqual = useRef(bigNumberify(0));

  const { data: equalInLiquidityOnFantom, mutate: mutateEqualInLiquidityOnFantom } = useSWR(
    [`StakeV2:equalInLiquidity:${FTM}`, FTM, getContract(FTM, "EQUAL"), "balanceOf", poolAddressFantom],
    {
      fetcher: fetcher(undefined, Token),
    }
  );

  const mutate = useCallback(() => {
    mutateEqualInLiquidityOnFantom();
  }, [mutateEqualInLiquidityOnFantom]);

  if (equalInLiquidityOnFantom) {
    let total = bigNumberify(equalInLiquidityOnFantom);
    totalEqual.current = total;
  }
  return {
    fantom: equalInLiquidityOnFantom,
    total: totalEqual.current,
    mutate,
  };
}

export function useUserReferralCode(library, chainId, account) {
  const referralStorageAddress = getContract(chainId, "ReferralStorage");
  const { data: userReferralCode, mutate: mutateUserReferralCode } = useSWR(
    account && [`ReferralStorage:traderReferralCodes`, chainId, referralStorageAddress, "traderReferralCodes", account],
    {
      fetcher: fetcher(library, ReferralStorage),
    }
  );
  return {
    userReferralCode,
    mutateUserReferralCode,
  };
}
export function useReferrerTier(library, chainId, account) {
  const referralStorageAddress = getContract(chainId, "ReferralStorage");
  const { data: referrerTier, mutate: mutateReferrerTier } = useSWR(
    account && [`ReferralStorage:referrerTiers`, chainId, referralStorageAddress, "referrerTiers", account],
    {
      fetcher: fetcher(library, ReferralStorage),
    }
  );
  return {
    referrerTier,
    mutateReferrerTier,
  };
}
export function useCodeOwner(library, chainId, account, code) {
  const referralStorageAddress = getContract(chainId, "ReferralStorage");
  const { data: codeOwner, mutate: mutateCodeOwner } = useSWR(
    account && code && [`ReferralStorage:codeOwners`, chainId, referralStorageAddress, "codeOwners", code],
    {
      fetcher: fetcher(library, ReferralStorage),
    }
  );
  return {
    codeOwner,
    mutateCodeOwner,
  };
}

function useEqualPriceFromFantom(library, active) {

  const poolAddress = getContract(FTM, "UniswapEqualUsdcPool");

  const { data: equalInLiquidityOnFantom, mutate: mutateEqualInLiquidityOnFantom } = useSWR(
    [`StakeV2:equalInLiquidity:${FTM}`, FTM, getContract(FTM, "EQUAL"), "balanceOf", poolAddress],
    {
      fetcher: fetcher(undefined, Token),
    }
  );

  const { data: usdcInLiquidityOnFantom, mutate: mutateUsdcInLiquidityOnFantom } = useSWR(
    [`StakeV2:usdcInLiquidity:${FTM}`, FTM, "0x04068DA6C83AFCFA0e13ba15A6696662335D5B75", "balanceOf", poolAddress],
    {
      fetcher: fetcher(undefined, Token),
    }
  );  

  const equalPrice = useMemo(() => {
    if (equalInLiquidityOnFantom && usdcInLiquidityOnFantom) {
      let totalEqual = (equalInLiquidityOnFantom / 1e18).toString();
      let totalUsdc = (usdcInLiquidityOnFantom / 1e6).toString();
 
      console.log("EQUAL PRICE", (totalUsdc).toString(), totalEqual.toString())
      if ((Number(totalUsdc) > 0 && (Number(totalEqual) > 0)))
      return bigNumberify((totalUsdc/totalEqual * 1e18).toFixed()).mul(bigNumberify(10).pow(12))
      else
      return bigNumberify((1 * 1e18).toFixed()).mul(bigNumberify(10).pow(12));
      // return bigNumberify(1)
    }
  }, [equalInLiquidityOnFantom, usdcInLiquidityOnFantom]);

  const mutate = useCallback(() => {
    mutateEqualInLiquidityOnFantom();
    mutateUsdcInLiquidityOnFantom();
  }, [mutateEqualInLiquidityOnFantom, mutateUsdcInLiquidityOnFantom]);

  return { data: equalPrice, mutate };
}

/* UNISWAP V3 PRICE GETTING */
/* function useEqualPriceFromFantom(library, active) {
  const poolAddress = getContract(FTM, "UniswapEqualUsdcPool");
  // const poolAddress = getContract(FTM, "UniswapEqualUsdcPool");
  const { data: uniPoolSlot0, mutate: updateUniPoolSlot0 } = useSWR(
    [`StakeV2:uniPoolSlot0:${active}`, FTM, poolAddress, "slot0"],
    {
      fetcher: fetcher(library, UniPool),
    }
  );

  const equalPrice = useMemo(() => {
    if (uniPoolSlot0) {
      return bigNumberify(((uniPoolSlot0.sqrtPriceX96 / 2 ** 96) ** 2 * 1e18).toFixed()).mul(bigNumberify(10).pow(24));
    }
  }, [uniPoolSlot0]);

  const mutate = useCallback(() => {
    updateUniPoolSlot0(undefined, true);
  }, [updateUniPoolSlot0]);

  return { data: equalPrice, mutate };
} */

export async function approvePlugin(
  chainId,
  pluginAddress,
  { library, pendingTxns, setPendingTxns, sentMsg, failMsg }
) {
  const routerAddress = getContract(chainId, "Router");
  const contract = new ethers.Contract(routerAddress, Router.abi, library.getSigner());
  return callContract(chainId, contract, "approvePlugin", [pluginAddress], {
    sentMsg,
    failMsg,
    pendingTxns,
    setPendingTxns,
  });
}

export async function registerReferralCode(chainId, referralCode, { library, ...props }) {
  const referralStorageAddress = getContract(chainId, "ReferralStorage");
  const contract = new ethers.Contract(referralStorageAddress, ReferralStorage.abi, library.getSigner());
  return callContract(chainId, contract, "registerCode", [referralCode], { ...props });
}
export async function setTraderReferralCodeByUser(chainId, referralCode, { library, ...props }) {
  const referralStorageAddress = getContract(chainId, "ReferralStorage");
  const contract = new ethers.Contract(referralStorageAddress, ReferralStorage.abi, library.getSigner());
  const codeOwner = await contract.codeOwners(referralCode);
  if (isAddressZero(codeOwner)) {
    helperToast.error("Referral code does not exist");
    return new Promise((resolve, reject) => {
      reject();
    });
  }
  return callContract(chainId, contract, "setTraderReferralCodeByUser", [referralCode], {
    ...props,
  });
}
export async function getReferralCodeOwner(chainId, referralCode) {
  const referralStorageAddress = getContract(chainId, "ReferralStorage");
  const provider = getProvider(null, chainId);
  const contract = new ethers.Contract(referralStorageAddress, ReferralStorage.abi, provider);
  const codeOwner = await contract.codeOwners(referralCode);
  return codeOwner;
}

export async function createSwapOrder(
  chainId,
  library,
  path,
  amountIn,
  minOut,
  triggerRatio,
  nativeTokenAddress,
  opts = {}
) {
  const executionFee = getConstant(chainId, "SWAP_ORDER_EXECUTION_GAS_FEE");
  const triggerAboveThreshold = false;
  let shouldWrap = false;
  let shouldUnwrap = false;
  opts.value = executionFee;

  if (path[0] === AddressZero) {
    shouldWrap = true;
    opts.value = opts.value.add(amountIn);
  }
  if (path[path.length - 1] === AddressZero) {
    shouldUnwrap = true;
  }
  path = replaceNativeTokenAddress(path, nativeTokenAddress);

  const params = [path, amountIn, minOut, triggerRatio, triggerAboveThreshold, executionFee, shouldWrap, shouldUnwrap];

  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner());

  return callContract(chainId, contract, "createSwapOrder", params, opts);
}

export async function createIncreaseOrder(
  chainId,
  library,
  nativeTokenAddress,
  path,
  amountIn,
  indexTokenAddress,
  minOut,
  sizeDelta,
  collateralTokenAddress,
  isLong,
  triggerPrice,
  opts = {}
) {
  invariant(!isLong || indexTokenAddress === collateralTokenAddress, "invalid token addresses");
  invariant(indexTokenAddress !== AddressZero, "indexToken is 0");
  invariant(collateralTokenAddress !== AddressZero, "collateralToken is 0");

  const fromETH = path[0] === AddressZero;

  path = replaceNativeTokenAddress(path, nativeTokenAddress);
  const shouldWrap = fromETH;
  const triggerAboveThreshold = !isLong;
  const executionFee = getConstant(chainId, "INCREASE_ORDER_EXECUTION_GAS_FEE");

  const params = [
    path,
    amountIn,
    indexTokenAddress,
    minOut,
    sizeDelta,
    collateralTokenAddress,
    isLong,
    triggerPrice,
    triggerAboveThreshold,
    executionFee,
    shouldWrap,
  ];

  if (!opts.value) {
    opts.value = fromETH ? amountIn.add(executionFee) : executionFee;
  }

  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner());

  return callContract(chainId, contract, "createIncreaseOrder", params, opts);
}

export async function createDecreaseOrder(
  chainId,
  library,
  indexTokenAddress,
  sizeDelta,
  collateralTokenAddress,
  collateralDelta,
  isLong,
  triggerPrice,
  triggerAboveThreshold,
  opts = {}
) {
  invariant(!isLong || indexTokenAddress === collateralTokenAddress, "invalid token addresses");
  invariant(indexTokenAddress !== AddressZero, "indexToken is 0");
  invariant(collateralTokenAddress !== AddressZero, "collateralToken is 0");

  const executionFee = getConstant(chainId, "DECREASE_ORDER_EXECUTION_GAS_FEE");

  const params = [
    indexTokenAddress,
    sizeDelta,
    collateralTokenAddress,
    collateralDelta,
    isLong,
    triggerPrice,
    triggerAboveThreshold,
  ];
  opts.value = executionFee;
  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner());

  return callContract(chainId, contract, "createDecreaseOrder", params, opts);
}

export async function cancelSwapOrder(chainId, library, index, opts) {
  const params = [index];
  const method = "cancelSwapOrder";
  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}

export async function cancelDecreaseOrder(chainId, library, index, opts) {
  const params = [index];
  const method = "cancelDecreaseOrder";
  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}

export async function cancelIncreaseOrder(chainId, library, index, opts) {
  const params = [index];
  const method = "cancelIncreaseOrder";
  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}

export async function updateDecreaseOrder(
  chainId,
  library,
  index,
  collateralDelta,
  sizeDelta,
  triggerPrice,
  triggerAboveThreshold,
  opts
) {
  const params = [index, collateralDelta, sizeDelta, triggerPrice, triggerAboveThreshold];
  const method = "updateDecreaseOrder";
  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}

export async function updateIncreaseOrder(
  chainId,
  library,
  index,
  sizeDelta,
  triggerPrice,
  triggerAboveThreshold,
  opts
) {
  const params = [index, sizeDelta, triggerPrice, triggerAboveThreshold];
  const method = "updateIncreaseOrder";
  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}

export async function updateSwapOrder(chainId, library, index, minOut, triggerRatio, triggerAboveThreshold, opts) {
  const params = [index, minOut, triggerRatio, triggerAboveThreshold];
  const method = "updateSwapOrder";
  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}

export async function _executeOrder(chainId, library, method, account, index, feeReceiver, opts) {
  const params = [account, index, feeReceiver];
  const positionManagerAddress = getContract(chainId, "PositionManager");
  const contract = new ethers.Contract(positionManagerAddress, PositionManager.abi, library.getSigner());
  return callContract(chainId, contract, method, params, opts);
}

export function executeSwapOrder(chainId, library, account, index, feeReceiver, opts) {
  return _executeOrder(chainId, library, "executeSwapOrder", account, index, feeReceiver, opts);
}

export function executeIncreaseOrder(chainId, library, account, index, feeReceiver, opts) {
  return _executeOrder(chainId, library, "executeIncreaseOrder", account, index, feeReceiver, opts);
}

export function executeDecreaseOrder(chainId, library, account, index, feeReceiver, opts) {
  return _executeOrder(chainId, library, "executeDecreaseOrder", account, index, feeReceiver, opts);
}

const NOT_ENOUGH_FUNDS = "NOT_ENOUGH_FUNDS";
const USER_DENIED = "USER_DENIED";
const SLIPPAGE = "SLIPPAGE";
const TX_ERROR_PATTERNS = {
  [NOT_ENOUGH_FUNDS]: ["not enough funds for gas", "failed to execute call with revert code InsufficientGasFunds"],
  [USER_DENIED]: ["User denied transaction signature"],
  [SLIPPAGE]: ["Router: mkt. price lower than limit", "Router: mkt. price higher than limit"],
};
export function extractError(ex) {
  if (!ex) {
    return [];
  }
  const message = ex.data?.message || ex.message;
  if (!message) {
    return [];
  }
  for (const [type, patterns] of Object.entries(TX_ERROR_PATTERNS)) {
    for (const pattern of patterns) {
      if (message.includes(pattern)) {
        return [message, type];
      }
    }
  }
  return [message];
}

function ToastifyDebug(props) {
  const [open, setOpen] = useState(false);
  return (
    <div className="Toastify-debug">
      {!open && (
        <span className="Toastify-debug-button" onClick={() => setOpen(true)}>
          Show error
        </span>
      )}
      {open && props.children}
    </div>
  );
}

export async function callContract(chainId, contract, method, params, opts) {
  try {
    if (!Array.isArray(params) && typeof params === "object" && opts === undefined) {
      opts = params;
      params = [];
    }
    if (!opts) {
      opts = {};
    }

    const txnOpts = {};

    if (opts.value) {
      txnOpts.value = opts.value;
    }

    txnOpts.gasLimit = opts.gasLimit ? opts.gasLimit : await getGasLimit(contract, method, params, opts.value);

    await setGasPrice(txnOpts, contract.provider, chainId);

    const res = await contract[method](...params, txnOpts);
    const txUrl = getExplorerUrl(chainId) + "tx/" + res.hash;
    const sentMsg = opts.sentMsg || "Transaction sent.";
    helperToast.success(
      <div>
        {sentMsg}{" "}
        <a href={txUrl} target="_blank" rel="noopener noreferrer">
          View status.
        </a>
        <br />
      </div>
    );
    if (opts.setPendingTxns) {
      const pendingTxn = {
        hash: res.hash,
        message: opts.successMsg || "Transaction completed!",
      };
      opts.setPendingTxns((pendingTxns) => [...pendingTxns, pendingTxn]);
    }
    return res;
  } catch (e) {
    let failMsg;
    const [message, type] = extractError(e);
    switch (type) {
      case NOT_ENOUGH_FUNDS:
        failMsg = <div>There is not enough FTM in your account on Fantom to send this transaction.</div>;
        break;
      case USER_DENIED:
        failMsg = "Transaction was cancelled.";
        break;
      case SLIPPAGE:
        failMsg =
          'The mkt. price has changed, consider increasing your Allowed Slippage by clicking on the "..." icon next to your address.';
        break;
      default:
        failMsg = (
          <div>
            {opts.failMsg || "Transaction failed."}
            <br />
            {message && <ToastifyDebug>{message}</ToastifyDebug>}
          </div>
        );
    }
    helperToast.error(failMsg);
    throw e;
  }
}

export function useTotalVolume() {
  const swrKey =  ["getTotalVolume"];
  let { data: totalVolume} = useSWR(swrKey, {
    fetcher: async (...args) => {
        try {
          return await getTotalVolumeFromGraph();
        } catch (ex2) {
          console.warn("getTotalVolumeFromGraph failed");
          console.warn(ex2);
          return [];
        }
      // }
    },
    dedupingInterval: 30000,
    focusThrottleInterval: 60000 * 5,
  });




  return totalVolume;
}


function getTotalVolumeFromGraph() {

  const requests = [];
  const nowTs = parseInt(Date.now() / 1000)

    const query = gql(`{
      volumeStats( 
        where: {period: total , timestamp_lte: ${nowTs}}  
        ) 
     {  
       margin    
       liquidation    
       swap    
       mint    
       burn
      }
    }`);
    requests.push(fantomGraphClient.query({ query }));



  return Promise.all(requests)
  .then((chunks) => {
    let totalVolume ;
    chunks.forEach((chunk) => {
      chunk.data.volumeStats.forEach((item) => {
        totalVolume = bigNumberify(item.margin)
          .add(bigNumberify(item.liquidation ))
          .add(bigNumberify(item.swap ))
          .add(bigNumberify(item.mint ))
          .add(bigNumberify(item.burn));

      });
    });

    return totalVolume;
  })
  .catch((err) => {
    console.error(err);
  });

}

export function useHourlyVolume() {
  const swrKey =  ["getHourlyVolume"];
  let { data: hourlyVolume} = useSWR(swrKey, {
    fetcher: async (...args) => {
        try {
          return await getHourlyVolumeFromGraph();
        } catch (ex2) {
          console.warn("getHourlyVolumeFromGraph failed");
          console.warn(ex2);
          return [];
        }
      // }
    },
    dedupingInterval: 30000,
    focusThrottleInterval: 60000 * 5,
  });




  return hourlyVolume;
}


function getHourlyVolumeFromGraph() {

  const requests = [];
  const secondsPerHour = 60 * 60;
  const minTime = parseInt(Date.now() / 1000 / secondsPerHour) * secondsPerHour - 24 * secondsPerHour;
  const nowTs = parseInt(Date.now() / 1000)

    const query = gql(`{
      volumeStats(
        where: {period: hourly, timestamp_gte: ${minTime}, timestamp_lte: ${nowTs}}
        orderBy: timestamp
        orderDirection: desc
        first: 50
      ) {
        timestamp
        margin
        liquidation
        swap
        mint
        burn
        __typename
      }
    }`);
    requests.push(fantomGraphClient.query({ query }));



  return Promise.all(requests)
  .then((chunks) => {
    let hourlyVolume = bigNumberify(0);
    chunks.forEach((chunk) => {
      chunk.data.volumeStats.forEach((item) => {
        hourlyVolume = hourlyVolume.add(bigNumberify(item.margin))
          .add(bigNumberify(item.liquidation ))
          .add(bigNumberify(item.swap ))
          .add(bigNumberify(item.mint ))
          .add(bigNumberify(item.burn));

      });
    });
    return hourlyVolume;
  })
  .catch((err) => {
    console.error(err);
  });

}

export function usePositionStats() {
  const swrKey =  ["getPositionStats"];
  let { data: positionStats} = useSWR(swrKey, {
    fetcher: async (...args) => {
        try {
          return await getPositionStats();
        } catch (ex2) {
          console.warn("getPositionStats failed");
          console.warn(ex2);
          return [];
        }
      // }
    },
    dedupingInterval: 30000,
    focusThrottleInterval: 60000 * 5,
  });
  return positionStats;
}

function getPositionStats() {

  const requests = [];
  const secondsPerHour = 60 * 60;
  const minTime = parseInt(Date.now() / 1000 / secondsPerHour) * secondsPerHour - 24 * secondsPerHour;
  const nowTs = parseInt(Date.now() / 1000)

    const query = gql(`{
      tradingStats(
        first: 1000
        orderBy: timestamp
        orderDirection: desc
        where: { period: "daily", timestamp_gte: ${minTime}, timestamp_lte: ${nowTs} }
      ) {
        timestamp
        profit
        loss
        profitCumulative
        lossCumulative
        longOpenInterest
        shortOpenInterest
      }
    }`);
    requests.push(fantomGraphClient.query({ query }));



  return Promise.all(requests)
  .then((chunks) => {
    let totalLongPositionSizes = bigNumberify(0);
    let totalShortPositionSizes = bigNumberify(0);
    chunks.forEach((chunk) => {
      chunk.data.tradingStats.forEach((item) => {
          totalLongPositionSizes = totalLongPositionSizes.add(bigNumberify(item.longOpenInterest));
          totalShortPositionSizes = totalShortPositionSizes.add(bigNumberify(item.shortOpenInterest));
      });
    });

    return {
      totalLongPositionSizes: totalLongPositionSizes.toString(), 
      totalShortPositionSizes: totalShortPositionSizes.toString()
    };
  })
  .catch((err) => {
    console.error(err);
  });

}

export function useTotalFees() {
  const swrKey =  ["getTotalFees"];
  let { data: totalFees } = useSWR(swrKey, {
    fetcher: async (...args) => {
        try {
          return await getTotalFeesFromGraph();
        } catch (ex2) {
          console.warn("getTotalFeesFromGraph failed");
          console.warn(ex2);
          return [];
        }
      // }
    },
    dedupingInterval: 30000,
    focusThrottleInterval: 60000 * 5,
  });




  return totalFees;
}


function getTotalFeesFromGraph() {

  const requests = [];

  const nowTs = parseInt(Date.now() / 1000)

  const query = gql(`{
    feeStats(
      first: 1000
      orderBy: id
      orderDirection: desc
      where: { period: daily, timestamp_gte: ${FIRST_DATE_TS}, timestamp_lte: ${nowTs} }
    ) {
      id
      margin
      marginAndLiquidation
      swap
      mint
      burn
      timestamp
    }
  }`);


    requests.push(fantomGraphClient.query({ query }));



  return Promise.all(requests)
  .then((chunks) => {
    let totalFees = bigNumberify(0);
    chunks.forEach((chunk) => {
      chunk.data.feeStats.forEach((item) => {
        totalFees = totalFees.add(bigNumberify(item.marginAndLiquidation))
          .add(bigNumberify(item.swap ))
          .add(bigNumberify(item.mint ))
          .add(bigNumberify(item.burn));

      });
    });

    return totalFees;
  })
  .catch((err) => {
    console.error(err);
  });

}