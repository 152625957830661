import React, { useEffect } from "react";
import Footer from "../../Footer";
import "./Buy.css";
import TokenCard from "../../components/TokenCard/TokenCard";
import BuyEQUALIcon from "../../img/ic_buy_equity.svg";
import SEO from "../../components/Common/SEO";
import { getPageTitle } from "../../Helpers";

export default function BuyEQUALEQUITY(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <SEO title={getPageTitle("Mint or EQUAL")}>
      <div className="BuyEQUALEQUITY page-layout">
        <div className="BuyEQUALEQUITY-container default-container buypage-container-pd" >

              <div className="section-title-block2   mb-5" >
                <div className="section-title-icon"><img src={BuyEQUALIcon} alt="BuyEQUALIcon" /></div>
                <div className="section-title-content">
                <div className="Page-title">BUY</div>
                  <div className="Page-description">EQUAL or EQUITY</div>
                </div>
              </div>
          <TokenCard />
        </div>
        <Footer />
      </div>
    </SEO>
  );
}
