export const CONTRACTS = {
  250: {
    // fantom
    Vault: "0xDd37b2eB92F97dd09cEd1f1d20A73aA340b2311A",
    Router: "0xEdCD6442143188Deb586e182B7900dFb8707Bc27",
    VaultReader: "0xF6aFcd935c28365CCe278Fed78b26Fe2BC9ae518",
    Reader: "0xcCf27643fa6C4FC844a8945b7c2F8bd562153649",
    EquityManager: "0xB0015714B541A99265f529c7c0d34DA47deCA5b2",
    RewardRouter: "0xf42Fe9b8e2009be073f1AeDc57a72623AC4F3045",
    RewardReader: "0xf5288515FD1394B814e38293f41dC22ad8085532",
    EQUITY: "0xe6dA6E31540239b4a09eC574f147004D50f1b140",
    EQUAL: "0x3Fd3A0c85B70754eFc07aC9Ac0cbBDCe664865A6", // DONE EQUAL
    ES_EQUAL: "0xc22Ffa318051d8aF4E5f2E2732d7049486fcE093",
    BN_EQUAL: "0x71e7EC880873af0FE33Ad988F862bE200FdD85cC",
    USDE: "0x393A6a6850e0788e496d73c50a436606e6CcA874",
    ES_EQUAL_IOU: "0x0000000000000000000000000000000000000000",
    StakedEqualTracker: "0x1886Fa412064137BDe9Ea996EA2bB85377de8aB6",
    BonusEqualTracker: "0xACF05e2a9b757e70C2900432c9b4E08Fdc54dD53",
    FeeEqualTracker: "0x85cd1F9550CdF662944bEc3E58021f8dbE6fE878",
    StakedEquityTracker: "0xFd6e1d4eaDbc80322F62e7148AD223596A005238",
    FeeEquityTracker: "0x77D32f6f5F454Fa76f93859f49f9431CeA8c225F",
    StakedEqualDistributor: "0x6AB47d47cF45C4aaA5c7F33c6632390674EfA294",
    StakedEquityDistributor: "0x8E7a8d3CAeEbbe9A92faC4db19424218aE6791a3",
    EqualVester: "0x8c634b72fF5d6A9F6a0281EEF36365E4db8bDF8d",
    EquityVester: "0x959306A913D041D4f634310f6aD3789cBF0e9b18",
    OrderBook: "0xa6f8431C9eEe4Ac2859207aF4004F7a948924c30",
    OrderExecutor: "0x8b9e409a817c7dC1a42C3DDe9E85dcBFF602c0fC",
    OrderBookReader: "0x8b9e409a817c7dC1a42C3DDe9E85dcBFF602c0fC",
    PositionRouter: "0xd99748782d7643b00C36a4Bb296C4A099dF98Ff3",
    ReferralStorage: "0x0000000000000000000000000000000000000000",
    ReferralReader: "0x0000000000000000000000000000000000000000",
    TraderJoeEqualAvaxPool: "0x0000000000000000000000000000000000000000",

    UniswapEqualUsdcPool: "0x76fa7935a5AFEf7fefF1C88bA858808133058908",
    NATIVE_TOKEN: "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83",
  },
  421611: {
    // arbitrum testnet
    Vault: "0xA4704fBfaf7c89511668052931Ba0f1816D2c9d3",
    Router: "0x526f42EA12E167E36E3De747187f53b6989d121A",
    Reader: "0x24e9C35B953F2aFb56f92592d2E8Ac2E58eA6912",
    NATIVE_TOKEN: "0xB47e6A5f8b33b3F17603C83a0535A9dcD7E32681",
    OrderBook: "0x84B1FEA4A2c1e0C07f34755ac4cf5aD26a07485d",
    OrderBookReader: "0x9Ed8Aea78D6Af0D4089f4D24066b8EFdA59747D9",

    // everything else is from 97
    AmmFactory: "0x6725f303b657a9451d8ba641348b6761a6cc7a17",
    AmmFactoryV2: "0x1111111111111111111111111111111111111111",
    GmxMigrator: "0xDEF2af818514c1Ca1A9bBe2a4D45E28f260063f9",
    USDE: "0x2D549bdBf810523fe9cd660cC35fE05f0FcAa028",
    GMT: "0xedba0360a44f885ed390fad01aa34d00d2532817",
    XGMT: "0x28cba798eca1a3128ffd1b734afb93870f22e613",
    GMT_USDE_PAIR: "0xe0b0a315746f51932de033ab27223d85114c6b85",
    XGMT_USDE_PAIR: "0x0108de1eea192ce8448080c3d90a1560cf643fa0",
    GMT_USDE_FARM: "0xbe3cB06CE03cA692b77902040479572Ba8D01b0B",
    XGMT_USDE_FARM: "0x138E92195D4B99CE3618092D3F9FA830d9A69B4b",
    USDE_YIELD_TRACKER: "0x62B49Bc3bF252a5DB26D88ccc7E61119e3179B4f",
    XGMT_YIELD_TRACKER: "0x5F235A582e0993eE9466FeEb8F7B4682993a57d0",
    GMT_USDE_FARM_TRACKER_XGMT: "0x4f8EE3aE1152422cbCaFACd4e3041ba2D859913C",
    GMT_USDE_FARM_TRACKER_NATIVE: "0xd691B26E544Fe370f39A776964c991363aF72e56",
    XGMT_USDE_FARM_TRACKER_XGMT: "0xfd5617CFB082Ba9bcD62d654603972AE312bC695",
    XGMT_USDE_FARM_TRACKER_NATIVE: "0x0354387DD85b7D8aaD1611B3D167A384d6AE0c28",
    GMT_GMX_IOU: "0x47052469970C2484729875CC9E2dd2683fcE71fb",
    XGMT_GMX_IOU: "0xeB3733DFe3b68C9d26898De2493A3Bb59FDb4A7B",
    GMT_USDE_GMX_IOU: "0x481312655F81b5e249780A6a49735335BF6Ca7f4",
    XGMT_USDE_GMX_IOU: "0x8095F1A92526C304623483018aA28cC6E62EB1e1",
  },
  42161: {
    // arbitrum mainnet
    Vault: "0x489ee077994B6658eAfA855C308275EAd8097C4A",
    Router: "0xaBBc5F99639c9B6bCb58544ddf04EFA6802F4064",
    VaultReader: "0x2A735dd7725a4AC00D2F334F88764DDBF2EE6694",
    Reader: "0x2b43c90D1B727cEe1Df34925bcd5Ace52Ec37694",
    EquityManager: "0x321F653eED006AD1C29D174e17d96351BDe22649",
    RewardRouter: "0xA906F338CB21815cBc4Bc87ace9e68c87eF8d8F1",
    RewardReader: "0x8BFb8e82Ee4569aee78D03235ff465Bd436D40E0",
    NATIVE_TOKEN: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
    EQUITY: "0x4277f8F2c384827B5273592FF7CeBd9f2C1ac258",
    EQUAL: "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
    ES_EQUAL: "0xf42Ae1D54fd613C9bb14810b0588FaAa09a426cA",
    BN_EQUAL: "0x35247165119B69A40edD5304969560D0ef486921",
    USDE: "0x45096e7aA921f27590f8F19e457794EB09678141",
    ES_EQUAL_IOU: "0x6260101218eC4cCfFF1b778936C6f2400f95A954",
    StakedEqualTracker: "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
    BonusEqualTracker: "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
    FeeEqualTracker: "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",
    StakedEquityTracker: "0x1aDDD80E6039594eE970E5872D247bf0414C8903",
    FeeEquityTracker: "0x4e971a87900b931fF39d1Aad67697F49835400b6",

    StakedEqualDistributor: "0x23208B91A98c7C1CD9FE63085BFf68311494F193",
    StakedEquityDistributor: "0x60519b48ec4183a61ca2B8e37869E675FD203b34",

    EqualVester: "0x199070DDfd1CFb69173aa2F7e20906F26B363004",
    EquityVester: "0xA75287d2f8b217273E7FCD7E86eF07D33972042E",

    OrderBook: "0x09f77E8A13De9a35a7231028187e9fD5DB8a2ACB",
    OrderExecutor: "0x7257ac5D0a0aaC04AA7bA2AC0A6Eb742E332c3fB",
    OrderBookReader: "0xa27C20A7CF0e1C68C0460706bB674f98F362Bc21",

    PositionRouter: "0x3D6bA331e3D9702C5e8A8d254e5d8a285F223aba",

    UniswapGmxEthPool: "0x80A9ae39310abf666A87C743d6ebBD0E8C42158E",
    ReferralStorage: "0xe6fab3f0c7199b0d34d7fbe83394fc0e0d06e99d",
    ReferralReader: "0x8Aa382760BCdCe8644C33e6C2D52f6304A76F5c8",
  },
  43114: {
    // avalanche
    Vault: "0x9ab2De34A33fB459b538c43f251eB825645e8595",
    Router: "0x5F719c2F1095F7B9fc68a68e35B51194f4b6abe8",
    VaultReader: "0x80785f96743d5Aef7725d88256fdBCfF43fBd112",
    Reader: "0x2eFEE1950ededC65De687b40Fd30a7B5f4544aBd",
    EquityManager: "0xe1ae4d4b06A5Fe1fc288f6B4CD72f9F8323B107F",
    RewardRouter: "0x82147C5A7E850eA4E28155DF107F2590fD4ba327",
    RewardReader: "0x04Fc11Bd28763872d143637a7c768bD96E44c1b6",
    NATIVE_TOKEN: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
    EQUITY: "0x01234181085565ed162a948b6a5e88758CD7c7b8",
    EQUAL: "0x62edc0692BD897D2295872a9FFCac5425011c661",
    ES_EQUAL: "0xFf1489227BbAAC61a9209A08929E4c2a526DdD17",
    BN_EQUAL: "0x8087a341D32D445d9aC8aCc9c14F5781E04A26d2",
    USDE: "0xc0253c3cC6aa5Ab407b5795a04c28fB063273894",
    ES_EQUAL_IOU: "0x6260101218eC4cCfFF1b778936C6f2400f95A954", // placeholder address

    StakedEqualTracker: "0x2bD10f8E93B3669b6d42E74eEedC65dd1B0a1342",
    BonusEqualTracker: "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
    FeeEqualTracker: "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
    StakedEquityTracker: "0x9e295B5B976a184B14aD8cd72413aD846C299660",
    FeeEquityTracker: "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",

    StakedEqualDistributor: "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
    StakedEquityDistributor: "0xDd593Cf40734199afc9207eBe9ffF23dA4Bf7720",

    EqualVester: "0x472361d3cA5F49c8E633FB50385BfaD1e018b445",
    EquityVester: "0x62331A7Bd1dfB3A7642B7db50B5509E57CA3154A",

    OrderBook: "0x4296e307f108B2f583FF2F7B7270ee7831574Ae5",
    OrderExecutor: "0x4296e307f108B2f583FF2F7B7270ee7831574Ae5",
    OrderBookReader: "0xccFE3E576f8145403d3ce8f3c2f6519Dae40683B",

    PositionRouter: "0x195256074192170d1530527abC9943759c7167d8",

    TraderJoeGmxAvaxPool: "0x0c91a070f862666bbcce281346be45766d874d98",
    ReferralStorage: "0x827ed045002ecdabeb6e2b0d1604cf5fc3d322f8",
    ReferralReader: "0x505Ce16D3017be7D76a7C2631C0590E71A975083",
  },

  250: {
    // fantom
    Vault: "0x9e4105F9E2284532474f69e65680e440F4C91cb8",
    Router: "0xd311Fd89e8403c2E90593457543E99cECc70D511",
    VaultReader: "0x7e2EA31ec55225731C59f720CC89A9A26eF7aa48",
    Reader: "0x88171375F6236885f463341d001B419D477eDB74",
    EquityManager: "0x82f59f4a17f20Da83D5021e1D194323c5E375aC0",
    RewardRouter: "0x855FaC135Ba4D7bc79fdbE084dE255E87FA79237",
    RewardReader: "0x3e8B14B5534333A2B83a31d778ec3bCd9dc946f4",
    EQUITY: "0x8B3E5eFaA3eA8e875112c6464218c28Db8F0b4d9",
    EQUAL: "0x3Fd3A0c85B70754eFc07aC9Ac0cbBDCe664865A6", // DONE EQUAL
    ES_EQUAL: "0x5A420F4364B8C046d7134d5512a65151D4Db1413",
    BN_EQUAL: "0x24078e05EC0056539551d0e111c3660825919a5e",
    USDE: "0x6e3E0AF313933c27949BE9B5a3C6BC98e2860bB2",
    ES_EQUAL_IOU: "0x0000000000000000000000000000000000000000",
    StakedEqualTracker: "0x70f752c2EC0765c96996CA5fAA2cb8A5DD0E9057",
    BonusEqualTracker: "0x1128d6237124BdD54675F10393FC76DA071a2306",
    FeeEqualTracker: "0xa5bDF77FBa489512903c7683DC605B61a1235692",
    StakedEquityTracker: "0x1f2A3023339995d9e04971F5b01941351D9f220f",
    FeeEquityTracker: "0xBb061C7Dc70B852335f6F61764adEC930DC76Ebe",
    StakedEqualDistributor: "0xA25D60cAF6dA44c889005101f128Df68f6aC677c",
    StakedEquityDistributor: "0xBF2e43b6104171d221a22666517Da7F6f939018c",
    EqualVester: "0x4bC0bc6Cb6B315ce993b983D6bd02BD6D211C929",
    EquityVester: "0x0890F307663b11828454a6a80b56b5940e87b99E",
    OrderBook: "0xA83F31aF44e812d2EdF0536516e7D274cd7301B8",
    PositionManager: "0xCD9aFEab223ed4aCcE3E12E010932982E8368Fbc",
    OrderBookReader: "0x8f02357cb55DbAd26DF5a7558CD810D5D0f05f43",
    PositionRouter: "0xf2BfB9cA6e21b30034b9d56Cb4735d2c180cC7e1",
    ReferralStorage: "0x0000000000000000000000000000000000000000",
    ReferralReader: "0x0000000000000000000000000000000000000000",
    TraderJoeEqualAvaxPool: "0x0000000000000000000000000000000000000000",

    UniswapEqualUsdcPool: "0x76fa7935a5AFEf7fefF1C88bA858808133058908",
    NATIVE_TOKEN: "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83",
  },
};
