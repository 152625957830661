import React, { useState } from "react";
import useSWR from "swr";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";

const ClaimEsEqual = () => {
  return (
    <div>ClaimEsEqual</div>
  )
}

export default ClaimEsEqual

