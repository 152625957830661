import React from "react";
import cx from "classnames";

import "./BlueWaves.css";

const BlueWaves = () => {
  return (
    <div class="sea">
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wave">
      <div class="wave_fade">
        <div class="wave_translate">
          <div class="wave_skew">
            <div class="wave_graphic"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default BlueWaves;
